<template>
	<div class="m-5">
		<div class="flex items-center justify-between mb-2">
            <div class="flex items-center w-3/5">
                <div class="form-feedback form-feedback-right w-full">
                    <input v-model="search" ref="search"  type="text" class="form-control" placeholder="search invoice or customer name">
                    <div v-show="!search" class="form-control-feedback">
                        <span>
                            <i class="icon-search4 text-gray-400"></i>
                        </span>
                    </div>
                    <div v-if="search"  class="absolute inset-y-0 flex items-center right-3">
                        <a @click.prevent="search = ''" href="#">
                            <i class="icon-cancel-circle2 text-red-500"></i>
                        </a>
                    </div>
                    <div v-show="filteres.length > 0" ref="filterSearch" class="absolute inset-y-0 my-1 left-1 flex items-center rounded-l-sm overflow-hidden bg-white">
                        <div class="bg-gray-700 h-full flex items-center text-gray-100 px-1"><i class="icon-filter3" style="font-size: 12px"></i></div>
                        <div class="border border-l-0 border-gray-400 h-full flex items-center px-1">
                            <ul class="flex space-x-1">
                                <li v-for="(filter, i) in filteres" :key="i">{{filter}}
                                    <span v-show="filteres.length > 1 && filteres.length > i+1" class="italic">or</span>
                                </li>
                                <li class="flex items-center">
                                    <a @click="clearFilter" href="#" class="flex hover:text-red-500"><i class="icon-cross3"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div ref="filter" class="ml-2 relative">
                    <button @click="showFilter = !showFilter" type="button" class="bg-white border whitespace-nowrap relative rounded-sm h-9 w-full focus:outline-none hover:bg-gray-50">
                        <div class="relative h-full flex items-center">
                            <div class="h-full w-9 bg-gray-200 rounded-l-sm flex justify-center items-center">
                                <i class="icon-filter3"></i>
                            </div>
                            <div class="pl-3">
                                <span class="text-gray-500 pr-5">Filter</span>
                            </div>
                            <div class="h-9 w-9 flex items-center justify-center ml-auto"> 
                                <i class="icon-arrow-down22"></i>
                            </div>
                        </div>
                    </button>
                    <div v-show="showFilter" class="absolute bg-white border mt-0.5 w-full">
                        <ul class="my-1">
                            <li v-for="(filter, i) in filterStatus" :key="i" class="hover:bg-gray-100">
                                <a @click="filter.status = !filter.status" href="#" class="flex items-center py-1 px-1">
                                    <div class="flex w-4">
                                        <i v-show="filter.status" class="icon-checkmark2 text-teal-500"></i>
                                    </div>
                                    <div class="ml-1" :class="{'font-medium': filter.status}">{{filter.text}}</div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
               <div class="flex items-center">
                    <div class="flex items-center mr-10">
                        <div class="mr-3">{{pages.pageValue}} / {{pages.totalItems}}</div>
                        <div class="flex items-center space-x-1">
                            <button @click="getData(parseInt(pages.current_page) - 1)" :disabled="parseInt(pages.current_page) == 1" class="text-teal-500"><i class="icon-arrow-left12" style="font-size: 20px"></i></button>
                            <button @click="getData(parseInt(pages.current_page) + 1)" :disabled="parseInt(pages.current_page) == parseInt(pages.last_page)" class="text-teal-500"><i class="icon-arrow-right13" style="font-size: 20px"></i></button>
                        </div>
                    </div>
                    <button class="h-7 w-7 bg-gray-100 border  -mr-px"><i class="icon-grid6"></i></button>
                    <button class="h-7 w-7 bg-gray-100 border"><i class="icon-list2"></i></button>
                </div>
            </div>
        </div>
		<div class="rounded-sm overflow-hidden">
            <table class="w-full">
                <thead>
                    <tr class="bg-blue-500 border border-blue-500 text-blue-50">
                        <th class="py-3 pl-5 pr-2" style="width: 14%">INVOICE #</th>
                        <th class="py-3 px-2" style="width: 30%">CUSTOMER</th>
                        <th class="py-3 px-2" style="width: 12%">INVOICE DATE</th>
                        <th class="py-3 px-2" style="width: 12%">DUE DATE</th>
                        <th class="py-3 px-2 text-right" style="width: 13%">TOTAL</th>
                        <th class="py-3 px-2" style="width: 12%">PAYMENT STATUS</th>
                        <th class="py-3 px-2" style="width: 7%">STATUS</th>
                    </tr>
                </thead>
                <tbody class="bg-white">
                    <tr v-for="(invoice, i) in invoices" :key="i" class="border" :class="{'bg-gray-50': i%2 == 0}">
                        <td class="py-2 pl-5 pr-2">
							<router-link @click.native="onDetail" :to="'/invoices/detail/'+invoice._id" class="hover:text-blue-500">
                                <div v-if="invoice.status == 'Draft'">/</div>
                                <div v-else>{{invoice.invoiceNo}}</div>
                            </router-link>
						</td>
                        <td class="py-2 px-2">{{invoice.customer}}</td>
                        <td class="py-2 px-2">{{getDate(invoice.createdAt)}}</td>
                        <td class="py-2 px-2">{{getDate(invoice.dueDate)}}</td>
                        <td class="py-2 px-2 text-right">{{Intl.NumberFormat('en-US', {minimumFractionDigits: 2}).format(invoice.grandTotal)}}</td>
                        <td class="py-2 px-2">
                            <div v-if="invoice.paymentStatus == 'Not Paid'" class="text-xs font-medium bg-gray-500 text-gray-50 inline-block rounded-sm px-1 py-px">{{invoice.paymentStatus}}</div>
                            <div v-if="invoice.paymentStatus == 'In Payment'" class="text-xs font-medium bg-yellow-500 text-yellow-50 inline-block rounded-sm px-1 py-px">{{invoice.paymentStatus}}</div>
                            <div v-if="invoice.paymentStatus == 'Partial'" class="text-xs font-medium bg-yellow-500 text-yellow-50 inline-block rounded-sm px-1 py-px">{{invoice.paymentStatus}}</div>
                            <div v-if="invoice.paymentStatus == 'Paid'" class="text-xs font-medium bg-green-500 text-green-50 inline-block rounded-sm px-1 py-px">{{invoice.paymentStatus}}</div>
                        </td>
                        <td class="py-2 px-2 flex items-center">
                            <div v-if="invoice.status == 'Draft'" class="h-3 w-3 bg-gray-300 rounded-full mr-1"></div>
                            <div v-if="invoice.status == 'Posted'" class="h-3 w-3 bg-blue-500 rounded-full mr-1"></div>
                            {{invoice.status}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="text-right mt-5">
            <paginate :pages="pages" @get-page="getData($event)"></paginate>
        </div>
	</div>
</template>
<script>
import axios from 'axios'
import BreadcrumbManager from '../../../mixins/breadcrumbsManager.vue'
import Paginate from '../../../components/paginate.vue'
import debounce from 'lodash.debounce'
import {moment} from '../../../moment'
export default {
	components: {Paginate},
    mixins: [BreadcrumbManager],
	data () {
		return {
			showFilter: false,
            filterStatus: [
                {status: false, text: 'Draft'},
                {status: false, text: 'Posted'},
            ], 
            search: '',
			invoices: [],
            pages: '',
            page: '',
            searchData: '',
            salesId: ''
		}
	},
	created () {
        this.searchData = debounce (() => {
            this.getData()
        }, 250)
    },
    beforeMount () {
        if(this.$route.query.salesId) {
            this.salesId = this.$route.query.salesId
            this.clearFilter()
            this.pushBreadcrumb(
                {text: 'Invoices'}
            )
        }
    },
	mounted () {
        this.setButtonText()
        this.setDiscard(false)
        this.getData()
        window.addEventListener('click', this.closeFilter)
        this.$nextTick(() => {
            let w = this.$refs.filterSearch.clientWidth + 11
            this.$refs.search.style.paddingLeft = w+'px'
        })
    },
    destroyed () {
        window.removeEventListener('click', this.closeFilter)
    },
	computed: {
        filteres () {
            let data = []
            for (let i=0; i<this.filterStatus.length; i++) {
                if (this.filterStatus[i].status == true) {
                    data.push(this.filterStatus[i].text)
                }
            }
            return data
        }
    },
	methods: {
		getData (i) {
            this.page = i
            let a = localStorage.getItem('customerInvoices')
            window.scrollTo(0, 0)
            let params = JSON.parse(a)
            if(params && !i) {
                this.search = params.search
                let index = this.filterStatus.find(obj => {
                    return obj.text == params.filter
                })
                if(index > -1) {
                    this.filterStatus[index].status = true
                }
                axios.get('/invoices', {
                    params: params
                })
                .then(res => {
                    this.invoices = res.data.data
                    this.pages = res.data.pages
                })
            } else {
                axios.get('/invoices', {
                    params: {
                        page: i,
                        search: this.search,
                        filter: this.filteres,
                        salesId: this.salesId
                    }
                })
                .then(res => {
                    this.invoices = res.data.data
                    this.pages = res.data.pages
                })
            }
		},
		clearFilter () {
            for (let i=0; i < this.filterStatus.length; i++) {
                this.filterStatus[i].status = false
            }
        },
        closeFilter (e) {
            if(!e || !this.$refs.filter.contains(e.target)) {
                this.showFilter = false
            }
        },
		getDate (d) {
            return moment(d)
        },
        onDetail () {
            let obj = {
                page: this.page,
                search: this.search,
                filter: this.filteres,
                salesId: this.salesId
            }
            localStorage.setItem('customerInvoices',JSON.stringify(obj))
        },
	},
    watch: {
        search () {
            let a = localStorage.getItem('customerInvoices')
            if(!a) {
                this.searchData()
            }
            localStorage.removeItem('customerInvoices')
        },
        filterStatus: {
            handler: function () {
                this.$nextTick(() => {
                    let w = this.$refs.filterSearch.clientWidth + 11
                    this.$refs.search.style.paddingLeft = w+'px'
                    this.getData()
                    localStorage.removeItem('customerInvoices')
                })
            },  
            deep: true
        }
    },
    beforeRouteLeave (to, from, next) {
        if(to.name == 'Invoice Detail') {
            this.replaceBreadcrumb({
                find: 'Invoices',
                replace: {text: 'Invoices', path: this.$route.fullPath}
            })
        } else {
            localStorage.removeItem('customerInvoices')
        }
        next()
    }
}
</script>